import { createStore } from "vuex";
import createPersistedState from "vuex-plugin-persistedstate";

export default createStore({
  state: {
    errorType: '',
    isVisible: false
  },
  getters: {
    errorType(state){
      return state.errorType
    },
    isVisible(state){
      return state.isVisible
    }
  },
  mutations: {
    setErrorType(state, newValue){
      state.errorType = newValue;
    },
    setIsVisible(state, newValue){
      state.isVisible = newValue;
    }
  },
  actions: {},
  modules: {
    activeItems: {
      namespaced: true,
      state(){
        return{
          navItemsAdditionalClasses: {
            'organisation': '',
            'tunnel': 'menu--item--active',
            'orders': '',
            'requests': '',
            'clients': '',
            'loyalty': '',
            'staff': '',
            'education': '',
            'storage': '',
            'reports': '',
            'settings': ''
          },
          activeNavSection: 'tunnel',
          sectionNavBarAdditionalClasses: {
            'create-ord': 'section--nav--item--active',
            'orders-log': ''
          },
          tunnelStepsAdditionalClasses:{
            'client': 'tunnel--step--active',
            'services': '',
            'payment': ''
          }
        }
      },
      mutations: {
        setDefaultOptions(state){
          state.tunnelStepsAdditionalClasses = {
            'client': 'tunnel--step--active',
            'services': '',
            'payment': ''
          };
        },
        setNavItemAdditionalClasses(state, data){
          state.navItemsAdditionalClasses[data[0]] = data[1];
        },
        setActiveNavSection(state, newValue){
          state.activeNavSection = newValue;
        },
        setSectionNavBarAdditionalClasses(state, data){
          state.sectionNavBarAdditionalClasses[data[0]] = data[1];
        },
        setTunnelStepsAdditionalClasses(state, data){
          state.tunnelStepsAdditionalClasses[data[0]] = data[1];
        }
      }

    },
    section: {
      namespaced: true,
      state() {
        return {
          tunnel_active_section: "create-ord",
          tunnel_create_ord_step: "client",
          isLogged: false,
          navAdditionalClass: '',
          burgerAdditionalClass: '',
          mainPartAdditionalClass: '',

        }
      },
      mutations: {
        setDefaultOptions(state){
          state.tunnel_active_section = "create-ord";
          state.tunnel_create_ord_step = "client";
        },
        toggleAdditionalClasses(state){
          state.navAdditionalClass = state.navAdditionalClass ? '' : 'nav--active';
          state.burgerAdditionalClass = state.burgerAdditionalClass ? '' : 'burger--active';
          state.mainPartAdditionalClass = state.mainPartAdditionalClass ? '' : 'main--part--active';
        },
        setIsLogged(state, newValue){
          state.isLogged = newValue;
        },
        set_tunnel_active_section(state, new_value){
          state.tunnel_active_section = new_value;
        },
        set_tunnel_create_ord_step(state, new_value){
          state.tunnel_create_ord_step = new_value;
        }
      }
    },
    client: {
      namespaced: true,
      state() {
        return{
          name: "Олег Симонов",
          phone: "79775422509",
          dateBirth: "14.12.1994",
          sex: "Мужской",
          generalServices: "Основной",
          additionalServices: "Нет",
          comment: "",
          cars: {
            'Е031МЕ799': {
              model: 'Volkswagen Jetta',
              generalCategories: 'Кат.01',
              additionalCategories: 'Все категории',
              isSelected: false,
            },
            'Т695ОВ777': {
              model: 'Kia Sorento',
              generalCategories: 'Кат.03',
              additionalCategories: 'Все категории',
              isSelected: false
            }
          },
          selectedCar: '',
          money: 0,
          bonus: 0,
          personalDiscount: 0
        }
      },
      mutations: {
        makeOptionActive(state, car){
          state.cars[car].isSelected = true;
        },
        makeOptionNonActive(state, car){
          state.cars[car].isSelected = false;
        },
        selectCar(state, car){
          state.selectedCar = car;
        }
      }
    },
    services: {
      namespaced: true,
      state(){
        return {
          quickAccess: {
            'Мойка кузова': [
              {name: "ЭКСПРЕСС", price: 590, color: '#00AC4F', num: '2.01.'},
              {name: "КОМФОРТ", price: 590, color: '#3AA8E1', num: '2.02.'},
              {name: "ЭКСТРА", price: 590, color: '#F8B110', num: '2.04.'},
                {name: "ИНТЕНСИВ", price: 590, color: '#EC100E', num: '2.03.'},
              {name: "ПРЕМИУМ", price: 590, color: '#9D0092', num: '2.05.'},
            ],
            'Комплекс (уборка+мойка)': [
              {name: "ЭКСПРЕСС", price: 1200, color: '#00AC4F', num: '1.01.'},
              {name: "КОМФОРТ", price: 1200, color: '#3AA8E1', num: '1.02.'},
              {name: "ЭКСТРА", price: 1200, color: '#F8B110', num: '1.04.'},
              {name: "ИНТЕНСИВ", price: 1200, color: '#EC100E', num: '1.03.'},
              {name: "ПРЕМИУМ", price: 1200, color: '#9D0092', num: '1.05.'},
            ]
          },
          servicesList: {
            '1.04.': {
              type: 'complex',
              fullName: '1.04. Комплекс Экстра',
              shortName: 'ЭКСТРА',
              price: 1200,
              discount: 0

            },
            '1.01.': {
              type: 'complex',
              fullName: '1.01. Комплекс Экспресс',
              shortName: 'ЭКСПРЕСС',
              price: 1200,
              discount: 0

            },
            '1.02.': {
              type: 'complex',
              fullName: '1.02. Комплекс Комфорт',
              shortName: 'КОМФОРТ',
              price: 1200,
              discount: 0

            },
            '1.03.': {
              type: 'complex',
              fullName: '1.03. Комплекс Интенсив',
              shortName: 'ИНТЕНСИВ',
              price: 1200,
              discount: 0

            },
            '1.05.': {
              type: 'complex',
              fullName: '1.05. Комплекс Премиум',
              shortName: 'ПРЕМИУМ',
              price: 1200,
              discount: 0

            },
            '2.04.': {
              type: 'wash',
              fullName: '2.04. Мойка Экстра',
              shortName: 'ЭКСТРА',
              price: 590,
              discount: 0

            },
            '2.01.': {
              type: 'wash',
              fullName: '2.01. Мойка Экспресс',
              shortName: 'ЭКСПРЕСС',
              price: 590,
              discount: 0

            },
            '2.02.': {
              type: 'wash',
              fullName: '2.02. Мойка Комфорт',
              shortName: 'КОМФОРТ',
              price: 590,
              discount: 0

            },
            '2.03.': {
              type: 'wash',
              fullName: '2.03. Мойка Интенсив',
              shortName: 'ИНТЕНСИВ',
              price: 590,
              discount: 0

            },
            '2.05.': {
              type: 'wash',
              fullName: '2.05. Мойка Премиум',
              shortName: 'ПРЕМИУМ',
              price: 590,
              discount: 0

            }
          }
        }
      }
    },
    order: {
      namespaced: true,
      state:{
        preSum: 0,
        personalDiscount: 0,
        generalDiscount: 0,
        sum: 0,
        list: {},
        date: '',
        time: '',
        comment: '',
        surplus: 0
      },
      mutations: {
        setDefaultOptions(state){
          state.preSum = 0;
          state.personalDiscount = 0;
          state.generalDiscount = 0;
          state.sum = 0;
          state.list = {};
          state.date = '';
          state.time = '';
          state.comment = '';
          state.surplus = 0;
        },
        changeSurplus(state, newSurplus){
          state.surplus = newSurplus;
        },
        changeComment(state, newComment){
          state.comment = newComment;
        },
        setTime(state, newTime){
          state.time = newTime;
        },
        setDate(state, newDate){
          state.date = newDate;
        },
        fillPersonalDiscount(state, newValue){
          state.personalDiscount = newValue;
        },
        setPreSum(state, newValue){
          state.preSum = newValue;
        },
        calcSum(state){
          state.sum = state.preSum*(1 - state.personalDiscount/100);
        },
        addToOrder(state, num){
          let amount = state.list[num];
          amount = amount ? amount + 1 : 1;
          state.list[num] = amount;
        },
        decreaseAmount(state, num) {
          let amount = state.list[num];
          if (amount) {
            amount = amount - 1;
            if (amount === 0){
              delete state.list[num];
            } else {
              state.list[num] = amount;
            }
          }
        },
        increaseAmount(state, num){
          let amount = state.list[num];
          if (amount){
            state.list[num] = amount + 1;
          }
        }

      },
      actions: {
        addToOder({commit}, num){
          commit('addToOrder', num);
        }
      }

    },
    payment: {
      namespaced: true,
      state(){
        return{
          types: [
            {type: 'card', sum: 0}
          ],
          cashIn: 0,
          cashOut: 0,
          confirmed: false,
        }
      },
      mutations: {
        setDefaultOptions(state){
          state.types = [{type: 'card', sum: 0}];
          state.cashIn = 0;
          state.cashOut = 0;
          state.confirmed = false;
        },
        plusToCashIn(state, sum){
          state.cashIn += parseInt(sum, 10);
        },
        changeCashIn(state, sum){
          state.cashIn = sum;
        },
        changeCashOut(state, sum){
          state.cashOut = sum;
        },
        changeSumWhenOne(state, newSum){
          state.types[0].sum = newSum;
        },
        addType(state, newType){
          state.types.push({type: newType, sum: 0});
        },
        removeType(state, id){
          state.types.splice(id, 1);
        },
        changeType(state, data){
          state.types[data[0]].type = data[1];
        },
        changeSum(state, data){
          state.types[data[0]].sum = data[1];
        }
      }
    }
  },
  plugins: [
      createPersistedState({
        paths: ["section", "client", "services", "order"],

      })
  ]
});
