
<template>
  <div class="main" v-show="this.$store.state.section.isLogged === true">
    <nav :class="'nav--block ' + this.$store.state.section.navAdditionalClass">
      <div class="nav--block--header">
        <div class="logo--block">
          <div class="logo--img--block"><img class="logo--img" id="nav--block--logo--img" src="../assets/icons/Logo%20500x500.png" alt="logo"></div>
          <div class="logo--text--block">
            <span class="logo--text">АкваСофт</span>
          </div>
        </div>
        <div class="burger--block" @click="toggleNavClass">
          <div :class="'burger ' + this.$store.state.section.burgerAdditionalClass">
            <div class="burger--active--background"></div>
            <div class="burger--container">
              <div class="burger--line"></div>
              <div class="burger--line"></div>
              <div class="burger--line"></div>
            </div>

          </div>

        </div>
      </div>

      <div class="menu--block">
        <ul class="menu">
          <li @click="makeActive(item.alt)" v-for="item in menu" :key="item.alt" class="list--item menu--item" v-bind:id="'menu--item--' + item.alt">
            <router-link :to="item.url" :class="'list--item--href menu--item--href ' + this.$store.state.activeItems.navItemsAdditionalClasses[item.alt]" :id="'menu--item--href--' + item.alt">
              <img class="icon menu--item--icon" :src=" require('../assets/icons/' + item.icon) " v-bind:alt="item.alt">
              <span>{{item.title}}</span>
            </router-link>
          </li>
        </ul>
      </div>
      <div class="nav--footer--block">
        <ul class="nav--footer">
          <li class="nav--footer--item" id="nav--footer--item--avatar">
            <router-link to="/profile">
              <img class="avatar--icon footer--icon" id="footer--avatar--icon" src="../assets/icons/avatar.png" alt="avatar">
            </router-link>
          </li>
          <li class="nav--footer--item" id="nav--footer--item--notifications">
            <router-link to="/notifications">
              <img class="bell--icon footer--icon" id="footer--bell--icon" src="../assets/icons/bell.svg" alt="bell">
            </router-link>
          </li>
          <li class="nav--footer--item" id="nav--footer--item--settings">
            <router-link to="/settings">
              <img class="settings--icon footer--icon" id="footer--settings--icon" src="../assets/icons/settings_small.svg" alt="settings">
            </router-link>
          </li>
          <li class="nav--footer--item" id="nav--footer--item--exit">
            <router-link to="/authorization">
              <img src="../assets/icons/exit.png" alt="exit" class="exit--icon footer--icon" id="footer--exit--icon" @click="exit">
            </router-link>

          </li>


        </ul>

      </div>
    </nav>
    <div :class="'main--part ' + this.$store.state.section.mainPartAdditionalClass">
      <router-view/>
    </div>

  </div>
</template>
<script>
import router from "@/router";

export  default {
  data: () => ({
    menu: [
      {title: 'Организация', url: '/organisation', icon: 'organisation.svg', alt: 'organisation'},
      {title: 'Тоннель', url: '/tunnel', icon: 'tunnel.svg', alt: 'tunnel'},
      {title: 'Заказы', url: '/orders', icon: 'orders.svg', alt: 'orders'},
      {title: 'Заявки', url: '/requests', icon: 'requests.svg', alt: 'requests'},
      {title: 'Клиенты', url: '/clients', icon: 'clients.svg', alt: 'clients'},
      {title: 'Лояльность', url: '/loyalty', icon: 'loyalty.svg', alt: 'loyalty'},
      {title: 'Сотрудники', url: '/staff', icon: 'staff.svg', alt: 'staff'},
      {title: 'Обучение', url: '/education', icon: 'education.svg', alt: 'education'},
      {title: 'Склад', url: '/storage', icon: 'storage.svg', alt: 'storage'},
      {title: 'Отчеты', url: '/reports', icon: 'reports.svg', alt: 'reports'},
      {title: 'Настройки', url: '/settings', icon:'settings.svg', alt: 'settings'}

    ],
  }),
  mounted() {
    document.querySelector('#menu--item--tunnel').addEventListener('click', ()=>{
      this.$store.commit("section/set_tunnel_active_section", 'create-ord');

      this.$store.commit('section/set_tunnel_create_ord_step', 'client');
    });

  },
  methods:{
    makeActive(id){
      let activeSection = this.$store.state.activeItems.activeNavSection;
      this.$store.commit('activeItems/setNavItemAdditionalClasses', [activeSection, '']);
      this.$store.commit('activeItems/setNavItemAdditionalClasses', [id, 'menu--item--active']);
      this.$store.commit('activeItems/setActiveNavSection', id);
      window.sessionStorage.setItem('navItemsAdditionalClasses', JSON.stringify(this.$store.state.activeItems.navItemsAdditionalClasses));
      window.sessionStorage.setItem('activeNavSection', id);
    },
    exit(){
      this.$store.commit('section/setIsLogged', false);
      window.sessionStorage.setItem('isLogged', false);

    },
    toggleNavClass(){
      this.$store.commit('section/toggleAdditionalClasses');
      window.sessionStorage.setItem('navAdditionalClass', this.$store.state.section.navAdditionalClass);
      window.sessionStorage.setItem('burgerAdditionalClass', this.$store.state.section.burgerAdditionalClass);
      window.sessionStorage.setItem('mainPartAdditionalClass', this.$store.state.section.mainPartAdditionalClass);
    }

  },
  beforeMount() {
    if (window.sessionStorage.getItem('navAdditionalClass')){
      this.$store.state.section.navAdditionalClass = window.sessionStorage.getItem('navAdditionalClass');
    }
    if (window.sessionStorage.getItem('burgerAdditionalClass')){
      this.$store.state.section.burgerAdditionalClass = window.sessionStorage.getItem('burgerAdditionalClass');
    }
    if (window.sessionStorage.getItem('mainPartAdditionalClass')){

      this.$store.state.section.mainPartAdditionalClass = window.sessionStorage.getItem('mainPartAdditionalClass');
    }
    if (window.sessionStorage.getItem('navItemsAdditionalClasses')){
      this.$store.state.activeItems.navItemsAdditionalClasses = JSON.parse(window.sessionStorage.getItem('navItemsAdditionalClasses'));
    }
    if (window.sessionStorage.getItem('activeNavSection')){
      this.$store.state.activeItems.activeNavSection = window.sessionStorage.getItem('activeNavSection');
    }

  }
}


</script>
<style lang="scss">
  .module--window{
    height: 100vh;
    width: 100vw;
    position: fixed;
    z-index: 100000;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(110, 110, 110, 0.5);
    backdrop-filter: blur(5px);
    .module--window--container{
      border-radius: 10px;
      background: #FAFBFC;
      padding: 30px;
      .bold{
        font-weight: 600 !important;
      }
      .red{
        color: #EC100E !important;
      }
      .module--window--title{
        color: #000;
        font-family: Inter, sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
      .module--window--main{
        margin-top: 15px;
        .module--window--subtitle{
          color: #7A7A7A;
          font-family: Inter, sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
        .module--window--list{
          margin-top: 5px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: start;
          gap: 5px;
          .module--window--list--item{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
            gap: 10px;
            .module--window--list--item--title{
              color: #000;
              font-family: Inter, sans-serif;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
            .module--window--list--item--text{
              color: #000;
              font-family: Inter, sans-serif;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }

          }
        }
      }
      .module--window--btns--block{
        margin-top: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;
        .module--window--success--btn{
          color: #fff;
          font-family: Inter, sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          background: #000;
          border: none;
          padding: 10px 20px;
          border-radius: 5px;
          &:focus{
            outline: 0;
          }
          &:hover{
            outline: 0;
            cursor: pointer;
          }
        }
        .module--window--fail--btn{
          color: #000;
          font-family: Inter, sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          background: #fff;
          border: 1px solid #7A7A7A;
          padding: 10px 20px;
          border-radius: 5px;
          &:focus{
            outline: 0;
          }
          &:hover{
            outline: 0;
            cursor: pointer;
          }
        }
      }

    }
  }
  .logo--img--block {
    .logo--img {
      height: 35px;
      width: 35px;

    }
  }
  .logo--text--block{
    &:hover{
      cursor: default;
    }
    .logo--text{
      color: #000;
      font-family: Inter, sans-serif;
      font-size: 23px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-left: 10px;
    }
  }
  .menu--item{
    .menu--item--href{
      color: #A6A399;
      font-family: Inter, sans-serif;
      font-size: 17px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-decoration: none;
      display: flex;
      align-items: center;
      flex-direction: row;
      padding: 5px;
      margin-bottom: 5px;
      border-radius: 10px;
      width: 180px;
      &:hover{
        background: #eeeeee;
        transition-duration: .5s;
        span{
          color: #6C6A65;
          transition-duration: .3s;
        }
      }
      .menu--item--icon{
        width: 29px;
        height: 29px;

      }
      span{
        display: block;
        margin-left: 15px;
      }
    }
  }
  .nav--active{
    left: 0 !important;
    background: #fbfbfa !important;
    border-right: 2.5px solid #efefef !important;
  }
  .nav--active:before{
    content: '';
    height: 44px;
    width: 20px;
    background: #FBFBFA;
    position: absolute;
    top: calc(50% - 12px);
    right: -23px;
    border: 2.5px solid #efefef;
    border-left: none;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    transition: all 0.15s ease-in-out;
    z-index: 1500;
  }
  .nav--block{
    width: 250px;
    height: 100vh;
    position: fixed;
    background: #ffffff;
    z-index: 1000;
    left: -210px;
    top: 0;
    padding: 20px;
    border-right-color: #ffffff;
    transition: left 0.35s ease-in-out;
    display: flex;
    flex-direction: column;
    justify-content: start;
    .menu--block{
      padding: 10px 0;
      .menu--item--active{
        background: #eeeeee;

        span{
          color: #6C6A65;
        }
      }
    }
    .burger--block{
      &:hover{
        cursor: pointer;
      }
      position: absolute;
      top: calc(50% - 10px);
      right: 10px;
      .burger{
        z-index: 2000;
        position: absolute;
        right: -10px;
        width: 30px;
        height: 45px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: #ffffff;
        border: none;
        transition: all 0.35s ease-in-out;
        border-bottom-right-radius: 25px;
        border-top-right-radius: 25px;
      }



      .burger--container{
        margin-right: 5px;
        display: inline-flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 5px;
        .burger--line{
          transition: transform 0.35s;
          width: 22px;
          height: 3px;
          background: #000;
          border-radius: 3px;
        }

      }
      .burger--active{
        right: -30px;
        background: #fbfbfa;

        transition: all 0.35s ease-in-out;
      }
      .burger--active .burger--line:nth-child(1){
        transform: translateX(-20%) translateY(160%) rotate(-30deg) scaleX(0.6);
      }
      .burger--active .burger--line:nth-child(2){
        width: 22px;
      }
      .burger--active .burger--line:nth-child(3){

        transform: translateX(-20%) translateY(-160%) rotate(30deg) scaleX(0.6);

      }

    }

    .nav--block--header{
      display: flex;
      flex-direction: row;
      padding: 10px 0;
      .logo--block{
        display: flex;
        flex-direction: row;
        align-items: center;
      }
    }
  }

  .main--part{
    margin-left: 30px;
    transition: all 0.35s ease-in-out;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    .tunnel--main{
      transition: all 0.35s ease-in-out;
    }
  }
  .main--part--active{
    margin-left: 250px !important;

    transition: all 0.35s ease-in-out;
    .tunnel--main{
      padding-left: 40px;
      transition: all 0.35s ease-in-out;
    }
  }
  .nav--footer{
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    position: absolute;
    left: 20px;
    bottom: 20px;
    .avatar--icon{
      height: 50px !important;
      width: 50px !important;
      margin-left: 0 !important;
    }
    .footer--icon{
      width: 25px;
      height: 25px;
      margin-left: 5px;
      margin-right: 5px;
    }
    .exit--icon{
      width: 30px;
      height: 30px;
      margin-right: 0 !important;
      &:hover{
        cursor: pointer;
      }
    }
  }

</style>
