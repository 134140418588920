import { createRouter, createWebHistory } from "vue-router";



const routes = [
  {
    path: '/',
    name: 'home',
    meta: {layout: 'main'},
    component: () => import('../views/Tunnel.vue')
  },
  {
    path: '/tunnel',
    name: 'tunnel',
    meta: {layout: 'main'},
    component: () => import('../views/Tunnel.vue'),

  },
  {
    path: '/clients',
    name: 'clients',
    meta: {layout: 'main'},
    component: () => import('../views/Clients.vue')
  },
  {
    path: '/education',
    name: 'education',
    meta: {layout: 'main'},
    component: () => import('../views/Education.vue')
  },
  {
    path: '/loyalty',
    name: 'loyalty',
    meta: {layout: 'main'},
    component: () => import('../views/Loyalty.vue')
  },
  {
    path: '/orders',
    name: 'orders',
    meta: {layout: 'main'},
    component: () => import('../views/Orders.vue')
  },
  {
    path: '/organisation',
    name: 'organisation',
    meta: {layout: 'main'},
    component: () => import('../views/Organisation.vue')
  },
  {
    path: '/reports',
    name: 'reports',
    meta: {layout: 'main'},
    component: () => import('../views/Reports.vue')
  },
  {
    path: '/requests',
    name: 'requests',
    meta: {layout: 'main'},
    component: () => import('../views/Requests.vue')
  },
  {
    path: '/settings',
    name: 'settings',
    meta: {layout: 'settings'},
    component: () => import('../views/Settings.vue')
  },
  {
    path: '/staff',
    name: 'staff',
    meta: {layout: 'main'},
    component: () => import('../views/Staff.vue')
  },
  {
    path: '/storage',
    name: 'storage',
    meta: {layout: 'main'},
    component: () => import('../views/Storage.vue')
  },
  {
    path: '/authorization',
    name: 'authorization',
    meta: {layout: 'auth'},
    component: () => import('../views/Authorization.vue')
  },
  {
    path: '/notifications',
    name: "notifications",
    meta: {layout: 'main'},
    component: () => import('../views/Notifications.vue')
  },
  {
    path: '/profile',
    name: 'profile',
    meta: {layout: 'settings'},
    component: () => import('../views/Profile.vue')
  }

];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
