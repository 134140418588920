<template>
  <div class="auth--layout">
    <div class="auth--left--block">
      <p class="copyright">2023 - 2024 ООО "АКВАСОФТ"</p>
    </div>
    <div class="auth--right--block">
      <router-view/>
    </div>
  </div>
</template>
<style lang="scss">
  .auth--left--block{
    background: #0A0A0A;
    display: inline-flex;
    height: 100vh;
    width: 400px;
    padding: 50px 120px 50px 120px;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    .copyright{
      color: #FFF;

      font-family: Inter, sans-serif;
      font-size: 10px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
  .auth--layout{
    display: flex;
    flex-direction: row;
  }
  .auth--right--block{
    width: calc(100vw - 400px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
</style>
