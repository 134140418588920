<template>
  <component :is="layout">
    <router-view/>
  </component>
</template>
<script>
  import AuthLayout from "@/layouts/AuthLayout.vue";
  import MainLayout from "@/layouts/MainLayout.vue";
  import SettingsLayout from "@/layouts/SettingsLayout.vue";
  import router from "@/router";
  export default {
    computed: {
      layout() {
        return (this.$route.meta.layout || 'main') + '-layout';
      }
    },
    components: {
      AuthLayout, MainLayout, SettingsLayout
    },
    beforeMount() {
      if (window.sessionStorage.getItem('isLogged')){
        let isLogged = window.sessionStorage.getItem('isLogged');
        if (isLogged === 'true'){
          isLogged = true;
        } else if(isLogged === 'false'){
          isLogged = false;
        }
        this.$store.state.section.isLogged = isLogged;

      }
      if (!this.$store.state.section.isLogged){
        router.push('authorization');


      }
    }
  }
</script>
<style lang="scss">
  ul{
    list-style: none;
    padding: 0;
    margin: 0;
  }
  *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-decoration: none;
  }
  .section--navigation--block{
    border-bottom: 3px solid #FBFBFA;
    width: 100%;
    padding: 25px 60px;
    .section--navigation{
      display: flex;
      flex-direction: row;
      justify-content: start;
      align-items: center;
      .section--navigation--item{
        color: #000;
        font-family: Inter, sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-right: 20px;
        &:hover{
          cursor: pointer;
          text-decoration: underline;
        }
      }
      .section--nav--item--active{
        text-decoration: underline;
      }

    }
  }

</style>
